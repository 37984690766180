import React from 'react';
import Section from 'components/Section';
import { SectionHeading } from 'components/Elements';
import s from './style.module.css';
import teams from './content';

const OurTeam = () => {
  return (
    <Section>
      <SectionHeading centered>Our team</SectionHeading>
      <ul className={s.team}>
        {teams.map((team, index) => (
          <li key={`team-${index}`}>
            {team.name && <h3>{team.name}</h3>}
            <ul>
              {team.members.map(({ name, title, bio, photo }) => (
                <li key={name}>
                  {photo && <img src={photo} />}
                  <h4>{name}</h4>
                  {title && <p>{title}</p>}
                  {bio && <p>{bio}</p>}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </Section>
  );
};

export default OurTeam;
