import React from 'react';
import Helmet from 'react-helmet';
import Page from 'components/Page';
import Section from 'components/Section';
import { font, typeset, Body } from 'components/Typography';
import { SectionHeading } from 'components/Elements';
import s from './style.module.css';

const Contact = () => {
  return (
    <Page className={s.root}>
      <Helmet title="Contact us" />
      <Section>
        <SectionHeading component="h1">Contact us</SectionHeading>

        <Body>
          <p>
            <strong>Support: </strong>
            <br />
            <a href="mailto:support@learningwithexperts.com">support@learningwithexperts.com</a>
          </p>
          <p>
            <strong>General enquiries: </strong>
            <br />
            <a href="mailto:info@learningwithexperts.com">info@learningwithexperts.com</a>
          </p>
          <p>
            <strong>Press: </strong>
            <br />
            <a href="mailto:press@learningwithexperts.com">press@learningwithexperts.com</a>
          </p>
        </Body>

        <Body>
          <p>
            <strong>Post: </strong>
            <br />
            Learning with experts
            <br />
            c/o MyOnlineSchool Ltd
            <br />
            Oxford Centre for Innovation
            <br />
            New Road
            <br />
            Oxford
            <br />
            OX1 1BY
          </p>
        </Body>
      </Section>
    </Page>
  );
};

export default Contact;
