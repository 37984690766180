import React from 'react';
import Section from 'components/Section';
import { SectionHeading } from 'components/Elements';
import s from './style.module.css';

const OurStory = () => {
  return (
    <Section className={s.root}>
      <SectionHeading centered white>
        Our story
      </SectionHeading>
      <ol className={s.story}>
        <li>
          <h3>Pre-2015</h3>
          <ul>
            <li>Founder Elspeth Briscoe graduates with a Masters in Garden Design</li>
            <li>Launch of the horticultural community</li>
            <li>Launch of the photography community</li>
          </ul>
        </li>
        <li>
          <h3>2015</h3>
          <ul>
            <li>First round of investment raised</li>
            <li>CTO Dan Rosewarne joins the company</li>
          </ul>
        </li>
        <li>
          <h3>2016</h3>
          <ul>
            <li>Launch of classroom version 2.0</li>
            <li>Launch of the antiques community</li>
          </ul>
        </li>
        <li>
          <h3>2017</h3>
          <ul>
            <li>Renamed to Learning with experts</li>
            <li>RHS approved distance learning provider</li>
            <li>Launch of the jewellery community</li>
            <li>Launch of the food & drink community</li>
          </ul>
        </li>
        <li>
          <h3>2018</h3>
          <ul>
            <li>First B2B partnership with Goldsmiths</li>
            <li>Launch of the floristry community</li>
          </ul>
        </li>
        <li>
          <h3>2019</h3>
          <ul>
            <li>
              Rupert Pennant-Rea (Former editor of The Economist & Deputy Governor of the Bank of
              England) joins as Chairman
            </li>
            <li>BBC Good Food & BBC Gardener's World (Immediate Media) strategic partnership</li>
          </ul>
        </li>
        <li>
          <h3>2020</h3>
          <ul>
            <li>Second round of investment raised</li>
            <li>New production team (formerly Channel 4 News) joins the company</li>
            <li>Switched to remote filming methods during the Covid-19 lockdown</li>
            <li>Launch of wellbeing community</li>
            <li>Partnership with Professor Sir Muir Gray, Former NHS Chief Knowledge Officer</li>
            <li>
              Launch of B2B courses with The Goldsmiths Centre and the University of Buckingham
            </li>
            <li>Launch of River Cottage Cooking Diploma</li>
          </ul>
        </li>
      </ol>
    </Section>
  );
};

export default OurStory;
