import React from 'react';
import Page from 'components/Page';
import Helmet from 'react-helmet';
import WhatWeBelieve from './components/WhatWeBelieve';
import OurMission from './components/OurMission';
import OurStory from './components/OurStory';
import OurTeam from './components/OurTeam';

const About = () => {
  return (
    <Page>
      <Helmet
        title="About - Learning with Experts"
        meta={[
          {
            name: 'description',
            content:
              'Learning with Experts offer a range of courses across gardening, photography and more, delivered online so you can learn when you like. Visit us.',
          },

          { name: 'og:title', content: 'About Learning with Experts' },
          {
            name: 'og:image',
            content:
              'https://assets.learningwithexperts.com/4948804b-ad12-4f30-befb-f87d6faeaa21/1848x1054',
          },
          { name: 'og:description', content: 'Meet the team' },
          { name: 'og:url', content: 'https://www.learningwithexperts.com/about' },

          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@lwe_hub' },
          { name: 'twitter:title', content: 'About Learning with Experts' },
          {
            name: 'twitter:image',
            content:
              'https://assets.learningwithexperts.com/4948804b-ad12-4f30-befb-f87d6faeaa21/1848x1054',
          },
          { name: 'twitter:description', content: 'Meet the team' },
        ]}
      />
      <OurMission />
      <WhatWeBelieve />
      <OurTeam />
      <OurStory />
    </Page>
  );
};

export default About;
