import React from 'react';
import Section from 'components/Section';
import { SectionHeading } from 'components/Elements';
import s from './style.module.css';

const WhatWeBelieve = () => {
  return (
    <Section className={s.root}>
      <SectionHeading centered white>
        What we believe…
      </SectionHeading>
      <ul className={s.list}>
        <li>
          In using human interaction to eliminate the isolation of distance learning through unique
          online classrooms
        </li>
        <li>Learning is for life</li>
        <li>In enhancing human leadership with the latest technologies</li>
        <li>
          In preserving our ancestral roots that value the strength of shared knowledge and the
          wisdom of elders
        </li>
        <li>Learning should be available wherever and whomever you are</li>
        <li>Learning should be fun</li>
      </ul>
    </Section>
  );
};

export default WhatWeBelieve;
