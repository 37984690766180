export default [
  {
    members: [
      {
        name: 'Elspeth Briscoe',
        title: 'CEO / Founder',
        bio: 'Formerly eBay, Skype, The Guardian',
        photo: require('./assets/elspeth.jpg'),
      },
      {
        name: 'Daniel Rosewarne',
        title: 'CTO',
        bio: 'Formerly Journl, Oxfam, Scoot',
        photo: require('./assets/dan.jpg'),
      },
      {
        name: 'Stuart Dawson',
        title: 'Finance Director',
        bio: 'Formerly Taylor & Francis, Deloitte',
        photo: require('./assets/stuart.jpg'),
      },
      {
        name: 'Mark Fishleigh',
        title: 'Commercial Director',
        bio: '20+ years B to B tech',
        photo: require('./assets/mark.jpg'),
      },
      {
        name: 'Alex Nott',
        title: 'Head of Growth Marketing',
        bio: 'Formerly PrivateFly',
        photo: require('./assets/alex.jpg'),
      },
      {
        name: 'Rob Ludgate',
        title: 'Head of Production',
        bio: 'Formerly Channel 4, ITN',
        photo: require('./assets/robl.jpg'),
      },
      {
        name: 'Paul Crabtree',
        title: 'Head of Engineering',
        bio: 'Formerly Razorfish',
        photo: require('./assets/paul.jpg'),
      },
      {
        name: 'Steve Thompson',
        title: 'Creative Director',
        bio: 'Formerly Razorfish',
        photo: require('./assets/steve.jpg'),
      },
      {
        name: 'Saskia Heath',
        title: 'Head of Partnerships',
        bio: 'Formerly Investec',
        photo: require('./assets/saskia.jpg'),
      },
      {
        name: 'Denis Nemytov',
        title: 'Frontend Developer',
        bio: 'Formerly Instruct ERIC',
        photo: require('./assets/denis.jpg'),
      },
      {
        name: 'Miles Walkden',
        title: 'Business Development Manager',
        bio: 'Formerly, Safety Media, Advanced Alchemy',
        photo: require('./assets/miles.jpg'),
      },
      {
        name: 'Jack Rolstone-Smith',
        title: 'Customer Success & Account Manager',
        bio: 'Formerly John Lewis',
        photo: require('./assets/jack.jpg'),
      },
      {
        name: 'Professor Sir Muir Gray',
        title: 'Chief Wellbeing Officer',
        bio: 'Formerly, Chief Knowledge Officer, NHS',
        photo: require('./assets/muir.jpg'),
      },
    ],
  },
  {
    name: 'Investors & Advisors',
    members: [
      {
        name: 'Rupert Pennant-Rea',
        title: 'Chairman',
        bio: 'Former editor of The Economist & Deputy Governor of the Bank of England',
        photo: require('./assets/rupert.jpg'),
      },
      {
        name: 'David Soskin',
        title: 'Investor',
        bio: 'HOWZAT Partners, Former CEO Cheapflights.com',
        photo: require('./assets/david.jpg'),
      },
      {
        name: 'Justin Cooke',
        title: 'Advisor & Investor',
        bio: 'Formerly Future Learn, Vice Chair of Unicef UK, Chair of The Drum, CEO and Founder of POSSIBLE',
        photo: require('./assets/justin.jpg'),
      },
      {
        name: 'Joanna Hardy',
        title: 'Investor',
        bio: 'Antiques Roadshow, Jewellery Specialist',
        photo: require('./assets/joanna.jpg'),
      },
      {
        name: 'Professor Alastair Nicholson',
        title: 'Advisor',
        bio: 'Saïd Business School, Oxford University',
        photo: require('./assets/alastair.jpg'),
      },
      {
        name: 'David Armfield',
        title: 'Investor',
        bio: 'Kinetix Critchleys Corporate Finance, Investment Banking',
        photo: require('./assets/davida.jpg'),
      },
      {
        name: 'Rob McGrigor',
        title: 'Investor',
        bio: 'Kinetix Critchleys Corporate Finance, Investment Banking',
        photo: require('./assets/robm.jpg'),
      },
      {
        name: 'James Eden',
        title: 'Investor',
        bio: 'Serial Startup Board Member',
        photo: require('./assets/james.jpg'),
      },
      {
        name: 'Ian Bannerman',
        title: 'Investor',
        bio: 'Formerly MD of Taylor Francis, Scholarly Publishing',
        photo: require('./assets/ian.jpg'),
      },
      {
        name: 'Andy McIndoe',
        title: 'Investor',
        bio: 'Former MD of Hilliers, record holder for Consecutive Gold medals at Chelsea Flower Show',
        photo: require('./assets/andy.png'),
      },
      {
        name: 'Dr Noel Kingsbury',
        title: 'Investor',
        bio: 'Author, Lecturer, Horticulturist',
        photo: require('./assets/noel.jpg'),
      },
    ],
  },
];
