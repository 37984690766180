import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import ProductVideoPlayer from 'components/ProductVideoPlayer';

const VideoStyle = styled.div`
  & div.video-js.vjs-paused button.vjs-big-play-button::after {
    content: 'Watch the film';
  }
`;

const promo = {
  id: 1,
  slug: '',
  trailerHlsUrl: 'https://stream.mux.com/VhvuMLLunX1DTCwlpdmbjDrwRqUZNKye.m3u8',
  files: {
    poster: {
      id: 1,
      uuid: '4948804b-ad12-4f30-befb-f87d6faeaa21',
    },
  },
  school: {
    slug: 'foodanddrink',
  },
};

const Showreel = () => {
  return (
    <VideoStyle>
      <ProductVideoPlayer product={promo} showWatchPreview />
    </VideoStyle>
  );
};

export default Showreel;
