import React from 'react';
import Helmet from 'react-helmet';
import Page from 'components/Page';
import Section from 'components/Section';
import { SectionHeading } from 'components/Elements';
import s from './style.module.css';

const TermsAndConditions = () => {
  return (
    <Page className="terms-conditions-page">
      <Helmet title="Terms and Conditions" />
      <Section className={s.root}>
        <SectionHeading centered>Terms and Conditions</SectionHeading>
        <aside>Last updated November 2023</aside>
        <p>
          These Terms and Conditions, together with any and all other documents referred to herein,
          set out the terms under which Services are sold and provided by Us through this website,
          www.learningwithexperts.com (“Our Site”). Please read these Terms and Conditions carefully
          and ensure that you understand them before ordering any Services from Our Site. You will
          be required to read and accept these Terms and Conditions when ordering Services. If you
          do not agree to comply with and be bound by these Terms and Conditions, you will not be
          able to order Services through Our Site. These Terms and Conditions, as well as any and
          all Contracts are in the English language only.
        </p>
        <ol>
          <li>
            Definitions and Interpretation
            <ol>
              <li>
                In these Terms and Conditions, unless the context otherwise requires, the following
                expressions have the following meanings:
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <strong>&ldquo;Contract&rdquo;</strong>
                        </p>
                      </td>
                      <td>
                        <p>
                          means a contract for the purchase and sale of Services, as explained in
                          Clause 12;
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>&ldquo;Order&rdquo;</strong>
                        </p>
                      </td>
                      <td>
                        <p>means your order for the Services;</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>&ldquo;Order Confirmation&rdquo;</strong>
                        </p>
                      </td>
                      <td>
                        <p>means our acceptance and confirmation of your Order;</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>&ldquo;Services&rdquo;</strong>
                        </p>
                      </td>
                      <td>
                        <p>
                          means the services which are to be provided by Us to you as specified in
                          your Order (and confirmed in Our Order Confirmation); and
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>&ldquo;We/Us/Our&rdquo;</strong>
                        </p>
                      </td>
                      <td>
                        <p>
                          means MYONLINESCHOOL LTD, a company registered in England under 08877754,
                          whose registered address and main trading address is Oxford Centre For
                          Innovation, New Road, Oxford, England, OX1 1BY.
                        </p>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </li>

          <li>
            Information About Us
            <ol>
              <li>
                Our Site, www.learningwithexperts.com, is owned and operated by MYONLINESCHOOL LTD,
                a limited company registered in England under 08877754, whose registered address and
                main trading address is Oxford Centre For Innovation, New Road, Oxford, England, OX1
                1BY. Our VAT number is GB204387031.
              </li>
            </ol>
          </li>

          <li>
            Access to and Use of Our Site
            <ol>
              <li>Access to Our Site is free of charge.</li>
              <li>
                It is your responsibility to make any and all arrangements necessary in order to
                access Our Site.
              </li>
              <li>
                Access to Our Site is provided &ldquo;as is&rdquo; and on an &ldquo;as
                available&rdquo; basis. We may alter, suspend or discontinue Our Site (or any part
                of it) at any time and without notice.&nbsp; We will not be liable to you in any way
                if Our Site (or any part of it) is unavailable at any time and for any period.
              </li>
              <li>
                Use of Our Site is subject to our Website Terms of Use. Please ensure that you have
                read them carefully and that you understand them.
              </li>
            </ol>
          </li>

          <li>
            Accounts
            <ol>
              <li>
                Certain parts of Our Site (including the ability to purchase services from Us) may
                require an Account in order to access them.
              </li>
              <li>
                When creating an Account, the information you provide must be accurate and complete.
                If any of your information changes at a later date, it is your responsibility to
                ensure that your Account is kept up-to-date.
              </li>
              <li>
                We require that you choose a strong password for your Account. It is your
                responsibility to keep your password safe. You must not share your Account with
                anyone else. If you believe your Account is being used without your permission,
                please contact Us immediately. We will not be liable for any unauthorised use of
                your Account.
              </li>
              <li>You must not use anyone else&rsquo;s Account.</li>
              <li>
                Any personal information provided in your Account will be collected, used, and held
                in accordance with your rights and Our obligations under the Data Protection Act
                1998, as set out in Clause 18.
              </li>
              <li>
                If you wish to close your Account, you may do so at any time. Closing your Account
                will result in the removal of your information.&nbsp; Closing your Account will also
                remove access to any areas of Our Site requiring an Account for access.
              </li>
            </ol>
          </li>

          <li>
            Intellectual Property Rights
            <ol>
              <li>
                With the exception of User Content (see Clause 6), all Content included on Our Site
                and the copyright and other intellectual property rights subsisting in that Content,
                unless specifically labelled otherwise, belongs to or has been licensed by Us. All
                Content (including User Content) is protected by applicable United Kingdom and
                international intellectual property laws and treaties.
              </li>
              <li>
                Subject to sub-Clauses 5.3 and 5.6 you may not reproduce, copy, distribute, sell,
                rent, sub-licence, store, or in any other manner re-use Content from Our Site unless
                given express written permission to do so by Us.
              </li>
              <li>
                You may:
                <ol>
                  <li>
                    Access, view and use Our Site in a web browser (including any web browsing
                    capability built into other types of software or app);
                  </li>
                  <li>Download Our Site (or any part of it) for caching;</li>
                  <li>Print pages from Our Site;</li>
                  <li>Download extracts from pages on Our Site; and</li>
                  <li>Save pages from Our Site for later and/or offline viewing.</li>
                </ol>
              </li>
              <li>
                Our status as the owner and author of the Content on Our Site (or that of identified
                licensors, as appropriate) must always be acknowledged.
              </li>
              <li>
                You may not use any Content saved or downloaded from Our Site for commercial
                purposes without first obtaining a licence from Us (or our licensors, as
                appropriate) to do so. This does not prohibit the normal access, viewing and use of
                Our Site for general information purposes whether by business users or consumers.
              </li>
              <li>
                Nothing in these Terms and Conditions limits or excludes the provisions of Chapter
                III of the Copyrights, Designs and Patents Act 1988 &lsquo;Acts Permitted in
                Relation to Copyright Works&rsquo;, covering in particular the making of temporary
                copies; the making of personal copies for private use; research and private study;
                the making of copies for text and data analysis for non-commercial research;
                criticism, review, quotation and news reporting; caricature, parody or pastiche; and
                the incidental inclusion of copyright material.
              </li>
            </ol>
          </li>

          <li>
            User Content
            <ol>
              <li>
                User Content on Our Site includes (but is not necessarily limited to) published
                assignments, comments, forum posts.
              </li>
              <li>
                An Account is required if you wish to submit User Content. For terms and conditions
                pertaining to Accounts, please refer to Clause 4.
              </li>
              <li>
                You agree that you will be solely responsible for your User Content. Specifically,
                you agree, represent and warrant that you have the right to submit the User Content
                and that all such User Content will comply with Our Acceptable Usage Policy,
                detailed below in Clause 10.
              </li>
              <li>
                You agree that you will be liable to Us and will, to the fullest extent permissible
                by law, indemnify Us for any breach of the warranties given by you under sub-Clause
                6.3. You will be responsible for any loss or damage suffered by Us as a result of
                such breach.
              </li>
              <li>
                You (or your licensors, as appropriate) retain ownership of your User Content and
                all intellectual property rights subsisting therein. When you submit User Content
                you grant Us an unconditional, non-exclusive, fully transferrable, royalty-free,
                perpetual, worldwide licence to use, store, archive, syndicate, publish, transmit,
                adapt, edit, reproduce, distribute, prepare derivative works from, display, perform
                and sub-licence your User Content for the purposes of operating and promoting Our
                Site. In addition, you also grant Other Users the right to copy and quote your User
                Content within Our Site.
              </li>
              <li>
                If you wish to remove User Content from Our Site, the User Content in question will
                be removed. Please note, however, that caching or references to your User Content
                may not be made immediately unavailable (or may not be made unavailable at all where
                they are outside of Our reasonable control).
              </li>
              <li>
                We may reject, reclassify, or remove any User Content from Our Site where, in Our
                sole opinion, it violates Our Acceptable Usage Policy, or If We receive a complaint
                from a third party and determine that the User Content in question should be removed
                as a result.
              </li>
            </ol>
          </li>

          <li>
            Links to Our Site
            <ol>
              <li>
                You may link to Our Site provided that:
                <ol>
                  <li>You do so in a fair and legal manner;</li>
                  <li>
                    You do not do so in a manner that suggests any form of association, endorsement
                    or approval on Our part where none exists;
                  </li>
                  <li>
                    You do not use any logos or trade marks displayed on Our Site without Our
                    express written permission; and
                  </li>
                  <li>
                    You do not do so in a way that is calculated to damage Our reputation or to take
                    unfair advantage of it.
                  </li>
                </ol>
              </li>
              <li>
                You may link to any page of Our Site provided you comply with the remainder of this
                Clause 7.
                <br />
              </li>
            </ol>
          </li>
          <li>
            Links to Other Sites
            <p>
              Links to other sites may be included on Our Site.&nbsp; Unless expressly stated, these
              sites are not under Our control.&nbsp; We neither assume nor accept responsibility or
              liability for the content of third party sites.&nbsp; The inclusion of a link to
              another site on Our Site is for information only and does not imply any endorsement of
              the sites themselves or of those in control of them.
            </p>
          </li>

          <li>
            Disclaimers
            <ol>
              <li>
                The Content on Our Site does not constitute advice on which you should rely. It is
                provided for general information purposes only.
              </li>
              <li>
                We make no representation, warranty, or guarantee that Our Site will meet your
                requirements, that it will not infringe the rights of third parties, that it will be
                compatible with all software and hardware, or that it will be secure. If, as a
                result of Our failure to exercise reasonable care and skill, any digital content
                from Our Site damages your device or other digital content belonging to you, you may
                be entitled to certain legal remedies.&nbsp; For more details concerning your rights
                and remedies as a consumer, please contact your local Citizens Advice Bureau or
                Trading Standards Office.
              </li>
              <li>
                We make reasonable efforts to ensure that the Content on Our Site is complete,
                accurate, and up-to-date. We do not, however, make any representations, warranties
                or guarantees (whether express or implied) that the Content is complete, accurate,
                or up-to-date.&nbsp; Please note that this exception does not apply to information
                concerning services for sale through Our Site.
              </li>
            </ol>
          </li>

          <li>
            Acceptable Usage Policy
            <ol>
              <li>
                You may only use Our Site in a manner that is lawful and that complies with the
                provisions of this Clause 10. Specifically:
                <ol>
                  <li>
                    you must ensure that you comply fully with any and all local, national or
                    international laws and/or regulations;
                  </li>
                  <li>
                    you must not use Our Site in any way, or for any purpose, that is unlawful or
                    fraudulent;
                  </li>
                  <li>
                    you must not use Our Site to knowingly send, upload, or in any other way
                    transmit data that contains any form of virus or other malware, or any other
                    code designed to adversely affect computer hardware, software, or data of any
                    kind; and
                  </li>
                  <li>
                    you must not use Our Site in any way, or for any purpose, that is intended to
                    harm any person or persons in any way.
                  </li>
                </ol>
              </li>
              <li>
                When submitting User Content (or communicating in any other way using Our Site), you
                must not submit, communicate or otherwise do anything that:
                <ol>
                  <li>is sexually explicit;</li>
                  <li>is obscene, deliberately offensive, hateful or otherwise inflammatory;</li>
                  <li>promotes violence;</li>
                  <li>promotes or assists in any form of unlawful activity;</li>
                  <li>
                    discriminates against, or is in any way defamatory of, any person, group or
                    class of persons, race, sex, religion, nationality, disability, sexual
                    orientation or age;
                  </li>
                  <li>
                    is intended or otherwise likely to threaten, harass, annoy, alarm,
                    inconvenience, upset, or embarrass another person;
                  </li>
                  <li>is calculated or is otherwise likely to deceive;</li>
                  <li>
                    is intended or otherwise likely to infringe (or threaten to infringe) another
                    person&rsquo;s right to privacy;
                  </li>
                  <li>
                    misleadingly impersonates any person or otherwise misrepresents your identity or
                    affiliation in a way that is calculated to deceive (obvious parodies are not
                    included within this definition provided that they do not fall within any of the
                    other provisions of this sub-Clause 10.2);
                  </li>
                  <li>implies any form of affiliation with Us where none exists;</li>
                  <li>
                    infringes, or assists in the infringement of, the intellectual property rights
                    (including, but not limited to, copyright, patents, trade marks and database
                    rights) of any other party; or
                  </li>
                  <li>
                    is in breach of any legal duty owed to a third party including, but not limited
                    to, contractual duties and duties of confidence.
                  </li>
                </ol>
              </li>
              <li>
                We reserve the right to suspend or terminate your access to Our Site if you
                materially breach the provisions of this Clause 10 or any of the other provisions of
                these Terms and Conditions. Specifically, We may take one or more of the following
                actions:
                <ol>
                  <li>
                    suspend, whether temporarily or permanently, your Account and/or your right to
                    access Our Site;
                  </li>
                  <li>
                    remove any User Content submitted by you that violates this Acceptable Usage
                    Policy;
                  </li>
                  <li>issue you with a written warning;</li>
                  <li>
                    take legal proceedings against you for reimbursement of any and all relevant
                    costs on an indemnity basis resulting from your breach;
                  </li>
                  <li>take further legal action against you as appropriate;</li>
                  <li>
                    disclose such information to law enforcement authorities as required or as We
                    deem reasonably necessary; and/or
                  </li>
                  <li>any other actions which We deem reasonably appropriate (and lawful).</li>
                </ol>
              </li>
              <li>
                We hereby exclude any and all liability arising out of any actions (including, but
                not limited to those set out above) that We may take in response to breaches of
                these Terms and Conditions.
              </li>
            </ol>
          </li>

          <li>
            Services, Pricing and Availability
            <ol>
              <li>
                We make all reasonable efforts to ensure that all general descriptions of the
                Services available from Us correspond to the actual Services that will be provided
                to you, however please note that the exact nature of the Services may vary depending
                upon your individual requirements and circumstances.
              </li>
              <li>
                Please note that sub-Clause 11.1 does not exclude Our responsibility for mistakes
                due to negligence on Our part and refers only to variations of the correct Services,
                not to different Services altogether.
              </li>
              <li>
                We neither represent nor warrant that all Services will be available at all times
                and cannot necessarily confirm availability until confirming your Order.
              </li>
              <li>
                We make all reasonable efforts to ensure that all prices shown on Our Site are
                correct at the time of going online. We reserve the right to change prices and to
                add, alter, or remove special offers from time to time and as necessary.&nbsp;
                Changes in price will not affect any Order that you have already placed (please note
                sub-Clause 11.6 regarding VAT, however).
              </li>
              <li>
                In the event that the price of Services you have ordered changes between your Order
                being placed and Us processing that Order and taking payment, you will be charged
                the price shown on Our Site at the time of placing your Order.
              </li>
              <li>
                All prices on Our Site include VAT. If the VAT rate changes between your order being
                placed and Us taking payment, the amount of VAT payable will be automatically
                adjusted when taking payment.
              </li>
            </ol>
          </li>

          <li>
            Orders &ndash; How Contracts Are Formed
            <ol>
              <li>
                Our Site will guide you through the ordering process. Before submitting your Order
                to Us you will be given the opportunity to review your Order and amend any
                errors.&nbsp; Please ensure that you have checked your Order carefully before
                submitting it.
              </li>
              <li>
                No part of Our Site constitutes a contractual offer capable of acceptance. Your
                Order constitutes a contractual offer that We may, at Our sole discretion, accept.
                &nbsp;Our acknowledgement of receipt of your Order does not mean that We have
                accepted it.&nbsp; Our acceptance is indicated by Us sending you an Order
                Confirmation by email.&nbsp; Only once We have sent you an Order Confirmation will
                there be a legally binding contract between Us and you (&ldquo;the Contract&rdquo;).
                The date of receipt of your purchase confirmation email will be the start date of
                your Services.
              </li>
              <li>Each Service purchased is for single use only.</li>
              <li>
                Each Service must be used within 12 months of Order Confirmation after which time
                the Service shall expire and shall no longer be available.
              </li>
              <li>
                The contract period and access to your Service is 12 months from the date of your
                Order Confirmation.
              </li>
              <li>
                You agree and acknowledge that you will lose your right to cancel this agreement or
                to change your mind once you have accessed the Service.
              </li>
              <li>
                Order Confirmations shall contain the following information:
                <ol>
                  <li>
                    Confirmation of the Services ordered including full details of the main
                    characteristics of those Services;
                  </li>
                  <li>
                    Fully itemised pricing for the Services ordered including, where appropriate,
                    taxes and other additional charges;
                  </li>
                </ol>
              </li>
              <li>
                If We, for any reason, do not accept or cannot fulfil your Order, no payment shall
                be taken under normal circumstances. If We have taken payment any such sums will be
                refunded to you as soon as possible and in any event within 7 days.
              </li>
              <li>
                You may change your Order at any time before We begin providing the Services by
                contacting Us.
              </li>
              <li>If you change your Order, We will confirm all agreed changes in writing.</li>
              <li>
                If you change your mind, you may cancel your Order or the Contract before or after
                We begin providing the Services subject to these Terms and Conditions. For details
                of your cancellation rights, please refer to Clauses 14 and 15.
              </li>
              <li>
                We may cancel your Order at any time before We begin providing the Services in the
                event that:
                <ol>
                  <li>
                    the required personnel and/or required materials necessary for the provision of
                    the Services are not available;
                  </li>
                </ol>
              </li>
              <li>
                If We cancel your Order under sub-Clause 12.8 and We have taken payment any such
                sums will be refunded to you as soon as possible and in any event within 7 days. If
                We cancel your Order, you will be informed by email and the cancellation will be
                confirmed in writing by email.
              </li>
            </ol>
          </li>

          <li>
            Payment
            <ol>
              <li>
                Price and payment details will be confirmed in the Order Confirmation. Your chosen
                payment method will be charged as indicated.
              </li>
              <li>
                We accept the following methods of payment on Our Site:
                <ol>
                  <li>Visa;</li>
                  <li>MasterCard;</li>
                  <li>American Express;</li>
                  <li>PayPal;</li>
                  <li>Klarna;</li>
                </ol>
              </li>
              <li>
                We do not charge any additional fees for any of the payment methods listed.
                <br />{' '}
              </li>
            </ol>
          </li>
          <li>
            Provision of the Services
            <ol>
              <li>
                As required by law, We will use all reasonable endeavours to provide the Services
                with reasonable skill and care, consistent with best practices and standards in the
                internet industry, and in accordance with any information provided by Us about the
                Services and about Us. We will begin providing the Services on the date agreed when
                you make your Order (which We shall confirm in the Order Confirmation).&nbsp; Please
                note that if you request that the Services begin within the statutory 14 calendar
                day cancellation (or &ldquo;cooling off&rdquo;) period, your right to cancel may be
                limited or lost.&nbsp; Please refer to Clause 15 for your statutory cancellation
                rights.&nbsp;
              </li>
              <li>
                We will continue providing the Services until the estimated completion date set out
                in the Order Confirmation.
              </li>
              <li>
                We will make every reasonable effort to provide the Services in a timely manner and
                to complete them on time. We cannot, however, be held responsible for any delays if
                an event outside of Our control occurs.&nbsp; Please refer to sub-Clause 20 for
                events outside of Our control.
              </li>
              <li>
                If We require any information or action from you in order to provide the Services,
                We will inform you of this as soon as is reasonably possible.
              </li>
              <li>
                If the information you provide or the action you take under sub-Clause 14.4 is
                delayed, incomplete or otherwise incorrect, We will not be responsible for any delay
                caused as a result. If additional work is required from Us to correct or compensate
                for a mistake made as a result of delayed, incomplete or otherwise incorrect
                information or action that you have provided or taken, We may charge you a
                reasonable additional sum for that work.
              </li>
              <li>
                In certain circumstances, for example where there is a delay in you sending Us
                information or taking action required under sub-Clause 10.4, We may suspend the
                Services (and will inform you of that suspension by email).
              </li>
              <li>
                In certain circumstances, for example where We encounter a technical problem, We may
                need to suspend or otherwise interrupt the Services to resolve the issue. Unless the
                issue is an emergency that requires immediate action We will inform you in advance
                by email before suspending or interrupting the Services.
              </li>
              <li>
                If the Services are suspended or interrupted under sub-Clauses 14.6, or 14.7 you
                will not be required to pay for them during the period of suspension. You must,
                however, pay any sums that may already be due by the appropriate due date(s).
              </li>
              <li>
                If you do not pay Us for the Services as required by Clause 9, We may suspend the
                Services until you have paid any and all outstanding sums due. If this happens, we
                will inform you by email.&nbsp; This does not affect Our right to charge you
                interest on any overdue sums under sub-Clause 17.4.
              </li>
            </ol>
          </li>

          <li>
            Your Legal Right to Cancel (Cooling Off Period)
            <ol>
              <li>
                If you are a consumer in the European Union, you have a legal right to a
                &ldquo;cooling off&rdquo; period within which you can cancel the Contract for any
                reason. This period begins once your Order is accepted and We have sent you an Order
                Confirmation, i.e. when the Contract between you and Us is formed.&nbsp; The period
                ends at the end of 14 calendar days after that date.
              </li>
              <li>
                If you wish to exercise your right to cancel under this Clause 15, you must inform
                Us of your decision within the cooling off period. Cancellation by email or by post
                is effective from the date on which you send Us your message. Please note that the
                cooling off period lasts for whole calendar days.&nbsp; If, for example, you send Us
                an email or letter by 23:59 on the final day of the cooling off period, your
                cancellation will be valid and accepted.&nbsp; If you would prefer to contact Us
                directly to cancel, please use the following details:
                <ol>
                  <li>
                    Email:{' '}
                    <a href="mailto:info@learningwithexperts.com">info@learningwithexperts.com</a>
                  </li>
                  <li>
                    Post: MyOnlineSchool Ltd, Oxford Centre for Innovation, New Road, Oxford,
                    Oxfordshire OX1 1BY
                  </li>
                </ol>
              </li>
              <li>
                We may ask you why you have chosen to cancel and may use any answers you provide to
                improve Our services in the future, however please note that you are under no
                obligation to provide any details if you do not wish to.
              </li>
              <li>
                As specified in sub-Clause 15.1, if the Services are to begin within the cooling off
                period you are required to make an express request to that effect. By requesting
                that the Services begin within the 14 calendar day cooling off period you
                acknowledge and agree to the following:
                <ol>
                  <li>
                    If the Services are fully performed within the 14 calendar day cooling off
                    period, you will lose your right to cancel after the Services are complete.
                  </li>
                  <li>
                    If you cancel after provision of the Services has begun but is not yet complete
                    you will be eligible for a full refund within the first 7 days, if the first
                    assignment of the course hasn&rsquo;t been submitted.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            Cancellation After the Legal Cancellation Period
            <ol>
              <li>
                Cancellation of Contracts after the 14 calendar day cooling off period has elapsed
                shall be subject to the specific terms governing those Services and may be subject
                to a minimum contract duration.
              </li>
              <li>
                If you wish to cancel under this Clause 16, you must inform Us of your decision to
                do so. You may do so in any way you wish. If you would prefer to contact Us
                directly, please use the following details:
                <ol>
                  <li>
                    Email:{' '}
                    <a href="mailto:info@learningwithexperts.com">info@learningwithexperts.com</a>
                  </li>
                  <li>
                    Post: MyOnlineSchool Ltd, Oxford Centre for Innovation, New Road, Oxford,
                    Oxfordshire OX1 1BY
                  </li>
                </ol>
              </li>
              <li>
                We may ask you why you have chosen to cancel and may use any answers you provide to
                improve Our services in the future, however please note that you are under no
                obligation to provide any details if you do not wish to.
              </li>
              <li>
                You may be entitled to cancel immediately by giving Us written notice in the
                following circumstances:
                <ol>
                  <li>
                    We breach the Contract in a material way and fail to remedy the breach within 7
                    days of you asking Us to do so in writing; or
                  </li>
                  <li>
                    We go into liquidation or have a receiver or administrator appointed over Our
                    assets; or
                  </li>
                  <li>We change these Terms and Conditions to your material disadvantage; or</li>
                  <li>
                    We are adversely affected by an event outside of Our control as under Clause
                    20).
                  </li>
                </ol>
              </li>
              <li>
                Eligibility for refunds may vary according to the Services ordered. You will be
                required to pay for Services supplied up until the point at which you inform Us that
                you wish to cancel (please note that this may include charges for preparatory work
                that We have undertaken where We have reasonably incurred costs).&nbsp; Such sums
                will be deducted from any refund due to you or, if no refund is due, We will invoice
                you for the relevant sums.&nbsp; Details of the relevant terms will be confirmed in
                Our Order Confirmation.&nbsp; If you are cancelling due to Our failure to comply
                with these Terms and Conditions or the Contract, you will not be required to make
                any payment to Us (unless such failure is due to an event outside of Our control or
                is due to your failure to comply with any of your obligations).
              </li>
              <li>
                Refunds under this Clause 12 will be issued to you within 7 days and in any event no
                later than 14 calendar days after the date on which you inform Us that you wish to
                cancel. Refunds will be made using the same payment method you used when ordering
                the Services.
              </li>
            </ol>
          </li>

          <li>
            Our Rights to Cancel
            <ol>
              <li>
                For cancellations before we begin providing the Services, please refer to sub-Clause
                8.9.
              </li>
              <li>
                We may cancel the Services after We have begun providing them due to an Event
                outside of Our control as under sub-Clause 20.2.4), or due to the non-availability
                of required personnel and/or required materials necessary for the provision of the
                Services. In such cases, no payment will be due from you and if you have already
                made any payment to us, such sums will be refunded to you.
              </li>
              <li>
                Refunds due under this Clause 16 will be issued to you within 7 days and in any
                event no later than 14 calendar days after the day on which We inform you of the
                cancellation. Refunds will be made using the same payment method you used when
                ordering the Services.
              </li>
              <li>
                We may cancel immediately by giving you written notice in the following
                circumstances:
                <ol>
                  <li>
                    You fail to make a payment by the due date as set out in Clause 14. This does
                    not affect Our right to charge you interest on any overdue sums as set out in
                    sub-Clause 14.9; or
                  </li>
                  <li>
                    You breach the contract in a material way and fail to remedy the breach within 7
                    days of Us asking you to do so in writing.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            Problems with the Services and Your Legal Rights
            <ol>
              <li>
                We always use reasonable endeavours to ensure that Our Services are trouble-free.
                If, however, there is a problem with the Services please contact Us as soon as is
                reasonable possible via email.
              </li>
              <li>
                We will use reasonable endeavours to remedy problems with the Services as quickly as
                is reasonably possible and practical.
              </li>
              <li>
                We will not charge you for remedying problems under this Clause 18 where the
                problems have been caused by Us, any of Our agents or sub-contractors, or where
                nobody is at fault. If We determine that a problem has been caused by you, including
                your provision of incorrect or incomplete information or taking of incorrect action,
                sub-Clause 14.5 will apply and We may charge you for the remedial work.
              </li>
              <li>
                As a consumer, you have certain legal rights with respect to the purchase of
                services. For full details of your legal rights and guidance on exercising them, it
                is recommended that you contact your local Citizens Advice Bureau or Trading
                Standards Office.&nbsp; If We do not perform the Services with reasonable skill and
                care, you have the right to request repeat performance or, if that is not possible
                or done within a reasonable time without inconvenience to you, you have the right to
                a reduction in price.&nbsp; If the Services are not performed in line with
                information that We have provided about them, you also have the right to request
                repeat performance or, if that is not possible or done within a reasonable time
                without inconvenience to you (or if Our breach concerns information about Us that
                does not relate to the performance of the Services), you have the right to a
                reduction in price.&nbsp; If for any reason We are required to repeat the Services
                in accordance with your legal rights, We will not charge you for the same and We
                will bear any and all costs of such repeat performance.&nbsp; In cases where a price
                reduction applies, this may be any sum up to the full Price and, where you have
                already made payment(s) to Us, may result in a full or partial refund.&nbsp; Any
                such refunds will be issued without undue delay (and in any event within 14 calendar
                days starting on the date on which We agree that you are entitled to the refund) and
                made via the same payment method originally used by you unless you request an
                alternative method.&nbsp; In addition to your legal rights relating directly to the
                Services, you also have remedies if We use materials that are faulty or incorrectly
                described.
              </li>
            </ol>
          </li>

          <li>
            Our Liability
            <ol>
              <li>
                We will be responsible for any foreseeable loss or damage that you may suffer as a
                result of Our breach of these Terms and Conditions or as a result of Our negligence.
                Loss or damage is foreseeable if it is an obvious consequence of Our breach or
                negligence or if it is contemplated by you and Us when the Contract is
                created.&nbsp; We will not be responsible for any loss or damage that is not
                foreseeable.
              </li>
              <li>
                We provide Services for domestic and private use or purposes. We make no warranty or
                representation that the Services are fit for commercial, business or industrial
                purposes of any kind.&nbsp; We will not be liable to you for any loss of profit,
                loss of business, interruption to business or for any loss of business opportunity.
              </li>
              <li>
                Nothing in these Terms and Conditions seeks to exclude or limit Our liability for
                death or personal injury caused by Our negligence (including that of Our employees,
                agents or sub-contractors); or for fraud or fraudulent misrepresentation.
              </li>
              <li>
                Nothing in these Terms and Conditions seeks to exclude or limit Our liability for
                failing to perform the Services with reasonable care and skill or in accordance with
                information provided by Us about the Services or about Us.
              </li>
              <li>
                Nothing in these Terms and Conditions seeks to exclude or limit your legal rights as
                a consumer. For more details of your legal rights, please refer to Your local
                Citizens Advice Bureau or Trading Standards Office.
              </li>
            </ol>
          </li>

          <li>
            Events Outside of Our Control (Force Majeure)
            <ol>
              <li>
                We will not be liable for any failure or delay in performing Our obligations where
                that failure or delay results from any cause that is beyond Our reasonable control.
                Such causes include, but are not limited to: power failure, internet service
                provider failure, industrial action by third parties, civil unrest, fire, explosion,
                flood, storms, earthquakes, subsidence, acts of terrorism, acts of war, governmental
                action, epidemic or other natural disaster, or any other event that is beyond Our
                reasonable control.
              </li>
              <li>
                If any event described under this Clause 20 occurs that is likely to adversely
                affect Our performance of any of Our obligations under these Terms and Conditions:
                <ol>
                  <li>We will inform you as soon as is reasonably possible;</li>
                  <li>
                    Our obligations under these Terms and Conditions (and therefore the Contract)
                    will be suspended and any time limits that We are bound by will be extended
                    accordingly;
                  </li>
                  <li>
                    We will inform you when the event outside of Our control is over and provide
                    details of any new dates, times or availability of Services as necessary;
                  </li>
                  <li>
                    If an event outside of Our control occurs and you wish to cancel the Contract as
                    a result, you may do so. Any refunds due to you as a result of such cancellation
                    will be paid to you as soon as is reasonably possible and in any event no later
                    than 14 calendar days after the date on which you inform Us that you wish to
                    cancel.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            Communication and Contact Details
            <p>
              You agree to receive the Learning with Experts newsletters and commercial information
              from Learning with Experts. Consent is voluntary. You have the right to withdraw my
              consent at any time (data is processed until the withdrawal of consent). You have the
              right to access, rectify, delete or limit processing of the data, the right to object,
              the right to submit a complaint to the supervisory authority or transfer the data. The
              administrator is Elspeth Briscoe, The Innovation Centre, 1 New Road, Oxford OX1 1BY.
              The administrator processes data following the Privacy Policy.
            </p>
            <ol>
              <li>
                If you wish to contact Us with general questions or complaints, you may contact Us
                by email at{' '}
                <a href="mailto:info@learningwithexperts.com">info@learningwithexperts.com</a>, or
                by post at MyOnlineSchool Ltd, Oxford Centre for Innovation, New Road, Oxford,
                Oxfordshire OX1 1BY.
              </li>
              <li>
                For matters relating to Our Services, your Order or cancellations, please contact Us
                by email at{' '}
                <a href="mailto:support@learningwithexperts.com">support@learningwithexperts.com</a>
                , or by post at MyOnlineSchool Ltd, Oxford Centre for Innovation, New Road, Oxford,
                Oxfordshire OX1 1BY.
              </li>
            </ol>
          </li>
          <li>
            Complaints and Feedback
            <ol>
              <li>
                We always welcome feedback from Our customers and, whilst We always use all
                reasonable endeavours to ensure that your experience as a customer of Ours is a
                positive one, We nevertheless want to hear from you if you have any cause for
                complaint.
              </li>
              <li>
                If you wish to complain about any aspect of your dealings with Us, please contact Us
                in by email, addressed to{' '}
                <a href="mailto:info@learningwithexperts.com">info@learningwithexperts.com</a>.
              </li>
            </ol>
          </li>
          <li>
            How We Use Your Personal Information (Data Protection)
            <ol>
              <li>
                All personal information that We may collect (including, but not limited to, your
                name, address and telephone number) will be collected, used and held in accordance
                with the provisions of the Data Protection Act 1998 and your rights under that Act.
              </li>
              <li>
                We may use your personal information to:
                <ol>
                  <li>Provide Our services to you;</li>
                  <li>Process your Order (including payment) for the Services; and</li>
                  <li>
                    Inform you of new products and/or services available from Us (if you opt or have
                    previously opted to receive it). You may request that we stop sending you this
                    information at any time.
                  </li>
                </ol>
              </li>
              <li>
                In certain circumstances (if, for example, you wish to purchase Services on credit),
                and with your consent, We may pass your personal information on to credit reference
                agencies. These agencies are also bound by the Data Protection Act 1998 and should
                use and hold your personal information accordingly.
              </li>
              <li>
                We will not pass on your personal information to any third parties without first
                obtaining your express permission.
              </li>
            </ol>
          </li>

          <li>
            Other Important Terms
            <ol>
              <li>
                We may transfer (assign) Our obligations and rights under these Terms and Conditions
                (and under the Contract, as applicable) to a third party (this may happen, for
                example, if We sell Our business). If this occurs, you will be informed by Us in
                writing.&nbsp; Your rights under these Terms and Conditions will not be affected and
                Our obligations under these Terms and Conditions will be transferred to the third
                party who will remain bound by them.
              </li>
              <li>
                You may not transfer (assign) your obligations and rights under these Terms and
                Conditions (and under the Contract, as applicable) without Our express written
                permission.
              </li>
              <li>
                The Contract is between you and Us. It is not intended to benefit any other person
                or third party in any way and no such person or party will be entitled to enforce
                any provision of these Terms and Conditions.
              </li>
              <li>
                If any of the provisions of these Terms and Conditions are found to be unlawful,
                invalid or otherwise unenforceable by any court or other authority, that / those
                provision(s) shall be deemed severed from the remainder of these Terms and
                Conditions. The remainder of these Terms and Conditions shall be valid and
                enforceable.
              </li>
              <li>
                No failure or delay by Us in exercising any of Our rights under these Terms and
                Conditions means that We have waived that right, and no waiver by Us of a breach of
                any provision of these Terms and Conditions means that We will waive any subsequent
                breach of the same or any other provision.
              </li>
              <li>
                We may revise these Terms and Conditions from time to time in response to changes in
                relevant laws and other regulatory requirements. If We change these Terms and
                Conditions at any time, We will give you at least 7 days written notice of the
                changes before they come into effect.&nbsp; If you wish to cancel the Contract as a
                result, please refer to sub-Clause 16.4.
              </li>
            </ol>
          </li>
          <li>
            Promotions, Offers, Sale Prices, Promotional codes and Discount codes
            <ol>
              <li>
                All promotional codes and offers are subject to our general Terms and Conditions.
              </li>
              <li>
                The promotional code must be entered into the Voucher Code field on the basket page
                and applied for the discount to be deducted from the order.
              </li>
              <li>
                Unless stated discounts cannot be combined and only one promotional code can be
                applied to any order.
              </li>
              <li>All RHS courses are excluded from any offers, unless otherwise stated. </li>
              <li>
                Items already reduced in price, displaying a 'Was' and 'Now' price are also excluded
                from promotional codes or discount or promotional offers.
              </li>
              <li>
                Offers, promotions, promotion codes and discount codes can be amended or withdrawn
                at any time.
              </li>
            </ol>
          </li>
          <li>
            Law and Jurisdiction
            <ol>
              <li>
                These Terms and Conditions, and the relationship between you and Us (whether
                contractual or otherwise) shall be governed by, and construed in accordance with,
                English law.
              </li>
              <li>
                If you are a consumer, any disputes concerning these Terms and Conditions, the
                relationship between you and Us, or any matters arising therefrom or associated
                therewith (whether contractual or otherwise) shall be subject to the jurisdiction of
                the courts of England, Wales, Scotland, or Northern Ireland, as determined by your
                residency.
              </li>
            </ol>
          </li>
        </ol>
      </Section>
    </Page>
  );
};

export default TermsAndConditions;
