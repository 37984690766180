import React from 'react';
import Helmet from 'react-helmet';
import Page from 'components/Page';
import Section from 'components/Page';
import { SectionHeading } from 'components/Elements';
import s from './style.module.css';

const Privacy = () => {
  return (
    <Page>
      <Helmet title="Privacy Policy" />
      <Section className={s.root}>
        <SectionHeading centered>Privacy Policy</SectionHeading>
        <aside>Last updated May 2018</aside>
        <h3>Background</h3>
        <p>
          MyOnlineSchool Ltd understands that your privacy is important to you and that you care
          about how your personal data is used. We respect and value the privacy of everyone who
          visits this website, www.learningwithexperts.com (Our Site) and will only collect and use
          personal data in ways that are described here, and in a way that is consistent with our
          obligations and your rights under the law.
        </p>
        <p>
          Please read this Privacy Policy carefully and ensure that you understand it. Your
          acceptance of this Privacy Policy is deemed to occur upon your first use of Our Site. You
          will be required to read and accept this Privacy Policy when signing up for an Account. If
          you do not accept and agree with this Privacy Policy, you must stop using Our Site
          immediately.
        </p>

        <h4>1. Definitions and Interpretation</h4>
        <p>In this Policy the following terms shall have the following meanings:</p>
        <table>
          <tbody>
            <tr>
              <td width="145" valign="top">
                <p align="left">
                  <strong>“Account”</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  means an account required to access and/or use certain areas and features of Our
                  Site;
                </p>
              </td>
            </tr>
            <tr>
              <td width="145" valign="top">
                <p align="left">
                  <strong>“Cookie”</strong>
                </p>
              </td>
              <td>
                <p>
                  means a small text file placed on your computer or device by OurSite when you
                  visit certain parts of Our Site and/or when you use certain features of OurSite.
                  Details of the Cookies used by OurSite are set out in Part 14, below; and{' '}
                  <strong></strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width="145" valign="top">
                <p align="left">
                  <strong>“Cookie Law”</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  means the relevant parts of the Privacy and Electronic Communications (EC
                  Directive) Regulations 2003;
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>2. Information About Us</h4>
        <p>
          Our site is owned and operated by MyOnlineSchool Ltd, a limited company registered in
          England under company number 08877754.
        </p>
        <p>Registered address: Oxford Centre For Innovation, New Road, Oxford, England, OX1 1BY.</p>
        <p>
          Main trading address: Oxford Centre For Innovation, New Road, Oxford, England, OX1 1BY.
        </p>
        <p>VAT number: GB204387031.</p>
        <h4>3. What Does This Policy Cover?</h4>
        <p>
          This Privacy Policy applies only to your use of Our Site. Our Site may contain links to
          other websites. Please note that we have no control over how your data is collected,
          stored, or used by other websites and we advise you to check the privacy policies of any
          such websites before providing any data to them.
        </p>
        <h4>4. What is Personal Data?</h4>
        <p>
          Personal data is defined by the General Data Protection Regulation (EU Regulation
          2016/679) (the “GDPR”) as ‘any information relating to an identifiable person who can be
          directly or indirectly identified in particular by reference to an identifier’.
        </p>
        <p>
          Personal data is, in simpler terms, any information about you that enables you to be
          identified. Personal data covers obvious information such as your name and contact
          details, but it also covers less obvious information such as identification numbers,
          electronic location data, and other online identifiers.
        </p>
        <h4>5. What Are My Rights?</h4>
        <p>Under the GDPR, you have the following rights, which we will always work to uphold:</p>
        <p>
          a) The right to be informed about Our collection and use of your personal data. This
          Privacy Policy should tell you everything you need to know, but you can always contact us
          to find out more or to ask any questions using the details in Part 15.
        </p>
        <p>
          b) The right to access the personal data we hold about you. Part 13 will tell you how to
          do this.
        </p>
        <p>
          c) The right to have your personal data rectified if any of your personal data held us is
          inaccurate or incomplete. Please contact us using the details in Part 15 to find out more.
        </p>
        <p>
          d) The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of
          any of your personal data that we have. Please contact us using the details in Part 15 to
          find out more.
        </p>
        <p>e) The right to restrict (i.e. prevent) the processing of your personal data.</p>
        <p>
          f) The right to object to us using your personal data for a particular purpose or
          purposes.
        </p>
        <p>
          g) The right to data portability. This means that, if you have provided personal data to
          us directly, we are using it with your consent or for the performance of a contract, and
          that data is processed using automated means, you can ask us for a copy of that personal
          data to re-use with another service or business in many cases.
        </p>
        <p>h) Rights relating to automated decision-making and profiling.</p>
        <p>
          For more information about Our use of your personal data or exercising your rights as
          outlined above, please contact us using the details provided in Part 15.
        </p>
        <p>
          Further information about your rights can also be obtained from the Information
          Commissioner’s Office or your local Citizens Advice Bureau.
        </p>
        <p>
          If you have any cause for complaint about Our use of your personal data, you have the
          right to lodge a complaint with the Information Commissioner’s Office.
        </p>
        <h4>6. What Data Do We Collect?</h4>
        <p>
          Depending upon your use of OurSite, we may collect some or all of the following personal
          and non-personal data (please also see Part 14 on Our use of Cookies and similar
          technologies):
        </p>
        <ul>
          <li>Name;</li>
          <li>Email address;</li>
          <li>IP address;</li>
          <li>Web browser type and version;</li>
          <li>Operating system;</li>
        </ul>
        <h4>7. How Do You Use My Personal Data?</h4>
        <p>
          Under the GDPR, we must always have a lawful basis for using personal data. This may be
          because the data is necessary for Our performance of a contract with you, because you have
          consented to Our use of your personal data, or because it is in Our legitimate business
          interests to use it. Your personal data will be used for the following purposes:
        </p>
        <ul>
          <li>Providing and managing your Account;</li>
          <li>Providing and managing your access to OurSite;</li>
          <li>Personalising and tailoring your experience on OurSite;</li>
          <li>
            Supplying Our products<strong></strong>and services to you. Your personal details are
            required in order for us<strong></strong>to enter into a contract with you.
          </li>
          <li>Communicating with you. This may include responding to emails or calls from you.</li>
          <li>
            Supplying you with information by email<strong></strong>that you have opted-in to (you
            may unsubscribe or opt-out at any time by contacting us at{' '}
            <a href="mailto:privacy@learningwithexperts.com">privacy@learningwithexperts.com</a>).
          </li>
        </ul>
        <p>
          With your permission and/or where permitted by law, we may also use your personal data for
          marketing purposes, which may include contacting you by email with information, news, and
          offers on Our<strong></strong>products <strong></strong>and<strong></strong>services. You
          will not be sent any unlawful marketing or spam. we will always work to fully protect your
          rights and comply with Our obligations under the GDPR and the Privacy and Electronic
          Communications (EC Directive) Regulations 2003, and you will always have the opportunity
          to opt-out.<strong></strong>
        </p>
        <p>
          Third Parties (including Stripe and Google Analytics) whose content appears on Our Site
          may use third-party Cookies, as detailed below in Part 14. Please refer to Part 14 for
          more information on controlling cookies. Please note that we do not control the activities
          of such third parties, nor the data that they collect and use themselves, and we advise
          you to check the privacy policies of any such third parties.
        </p>
        <p>
          If at any point you wish to query any action that we<strong></strong>take on the basis of
          this or wish to request ‘human intervention’ (i.e. have someone review the action
          themselves, rather than relying only on the automated method), the GDPR gives you the
          right to do so. Please contact us <strong></strong>to find out more using the details in
          Part 15.
        </p>
        <h4>8. How Long Will You Keep My Personal Data?</h4>
        <p>
          We will not keep your personal data for any longer than is necessary in light of the
          reason(s) for which it was first collected. Your personal data will therefore be kept for
          the following periods (or, where there is no fixed period, the following factors will be
          used to determine how long it is kept):
        </p>
        <ul>
          <li>
            Your name and email address will be retained as long as you need to have an account with
            us. If you want to delete your account, you can do so by contacting us.
          </li>
          <li>
            Any content you create within our classrooms will be retained as long as you would like
            to stay in the classroom. We provide access for life, however if you would like to
            remove your content, then contact us.
          </li>
        </ul>
        <p>9. How and Where Do You Store or Transfer My Personal Data?</p>
        <p>
          We will only store or transfer your personal data within the European Economic Area (the
          “EEA”). The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein.
          This means that your personal data will be fully protected under the GDPR or to equivalent
          standards by law.
        </p>
        <h4>10. Do You Share My Personal Data?</h4>
        <p>
          We will not share any of your personal data with any third parties for any purposes,
          subject to one important exception.
        </p>
        <p>
          In some limited circumstances, we may be legally required to share certain personal data,
          which might include yours, if we are involved in legal proceedings or complying with legal
          obligations, a court order, or the instructions of a government authority.
        </p>
        <h4>11. How Can I Control My Personal Data?</h4>
        <p>
          11.1 In addition to your rights under the GDPR, set out in Part 5, when <strong></strong>
          you submit personal data via Our Site, you may be given options to restrict Our use of
          your personal data. In particular, we aim to give you strong controls on Our use of your
          data for direct marketing purposes (including the ability to opt-out of receiving emails
          from us which you may do by unsubscribing using the links provided in Our emails.
        </p>
        <p>
          11.2 You may also wish to sign up to one or more of the preference services operating in
          the UK: The Telephone Preference Service (“the TPS”), the Corporate Telephone Preference
          Service (“the CTPS”), and the Mailing Preference Service (“the MPS”). These may help to
          prevent you receiving unsolicited marketing. Please note, however, that these services
          will not prevent you from receiving marketing communications that you have consented to
          receiving.
        </p>
        <p>12. Can I Withhold Information?</p>
        <p>
          You may access certain areas of Our Site without providing any personal data at all.
          However, to use all features and functions available on Our Site you may be required to
          submit or allow for the collection of certain data.<strong></strong>
        </p>
        <p>You may restrict Our use of Cookies. For more information, see Part 14.</p>
        <h4>13. How Can I Access My Personal Data?</h4>
        <p>
          If you want to know what personal data we have about you, you can ask us for details of
          that personal data and for a copy of it (where any such personal data is held). This is
          known as a “subject access request”.
        </p>
        <p>
          All subject access requests should be made in writing and sent to the email or postal
          addresses shown in Part 15. To make this as easy as possible for you, a Subject Access
          Request Form is available for you to use. You do not have to use this form, but it is the
          easiest way to tell us everything we need to know to respond to your request as quickly as
          possible.
        </p>
        <p>
          There is not normally any charge for a subject access request. If your request is
          ‘manifestly unfounded or excessive’ (for example, if you make repetitive requests) a fee
          may be charged to cover Our administrative costs in responding.
        </p>
        <p>
          We will respond to your subject access request within one month of receiving it. Normally,
          we aim to provide a complete response, including a copy of your personal data within that
          time. In some cases, however, particularly if your request is more complex, more time may
          be required up to a maximum of three months from the date we receive your request. You
          will be kept fully informed of Our progress.
        </p>
        <h4>14. How Do You Use Cookies?</h4>
        <p>
          Our Site may place and access certain first-party Cookies on your computer or device.
          First-party Cookies are those placed directly by us and are used only by us. We use
          Cookies to facilitate and improve your experience of Our Site and to provide and improve
          Our products<strong></strong>or services. We have carefully chosen these Cookies and have
          taken steps to ensure that your privacy and personal data is protected and respected at
          all times.
        </p>
        <p>
          By using Our Site, you may also receive certain third-party Cookies on your computer or
          device. Third-party Cookies are those placed by websites, services, and/or parties other
          than us. Third-party Cookies are used on Our Site for providing chat support, measuring
          and optimizing features and tracking the success of Our adverts shown on other web sites.
          For more details, please refer to the table below. These Cookies are not integral to the
          functioning of Our Site and your use and experience of Our Site will not be impaired by
          refusing consent to them.
        </p>
        <p>All Cookies used by and on Our Site are used in accordance with current Cookie Law.</p>
        <p>
          You may, if you wish, deny consent to the placing of Cookies; however certain features of
          Our Site may not function fully or as intended. <strong></strong>
        </p>
        <p>
          Certain features of Our Site depend on Cookies to function. Cookie Law deems these Cookies
          to be “strictly necessary”. These Cookies are shown in the table below. Your consent will
          not be sought to place these Cookies, but it is still important that you are aware of
          them. You may still block these Cookies by changing your internet browser’s settings as
          detailed below, but please be aware that Our Site may not work properly if you do so. We
          have taken great care to ensure that your privacy is not at risk by allowing them.
        </p>
        <p>The following first-party Cookies may be placed on your computer or device:</p>

        <div id="manage-cookies"></div>
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/f04b7bcd-2af5-4648-b5c5-91b15d4ac80d/cd.js"
          type="text/javascript"
          async
        ></script>

        <p>
          In addition to the controls that we provide, you can choose to enable or disable Cookies
          in your internet browser. Most internet browsers also enable you to choose whether you
          wish to disable all Cookies or only third-party Cookies. By default, most internet
          browsers accept Cookies, but this can be changed. For further details, please consult the
          help menu in your internet browser or the documentation that came with your device.
        </p>
        <p>
          You can choose to delete Cookies on your computer or device at any time, however you may
          lose any information that enables you to access Our Site more quickly and efficiently
          including, but not limited to, login and personalisation settings.
        </p>
        <p>
          It is recommended that you keep your internet browser and operating system up-to-date and
          that you consult the help and guidance provided by the developer of your internet browser
          and manufacturer of your computer or device if you are unsure about adjusting your privacy
          settings. <strong></strong>
        </p>
        <h4>15. How Do I Contact You?</h4>
        <p>
          To contact us about anything to do with your personal data and data protection, including
          to make a subject access request, please use the following details (for the attention of
          Elspeth Briscoe):
        </p>
        <p>
          Email address:{' '}
          <a href="mailto:privacy@learningwithexperts.com">privacy@learningwithexperts.com</a>.
        </p>
        <p>Telephone number: +44 1865 261400.</p>
        <p>
          Postal Address: Learning with experts, Oxford Centre for Innovation, New Road, Oxford, OX1
          1BY.
        </p>
        <h4>16. Changes to this Privacy Policy</h4>
        <p>
          We may change this Privacy Notice from time to time. This may be necessary, for example,
          if the law changes, or if we change Our business in a way that affects personal data
          protection.
        </p>
        <p>
          Any changes will be immediately posted on Our Site and you will be deemed to have accepted
          the terms of the Privacy Policy on your first use of Our Site following the alterations.
          Werecommend that you check this page regularly to keep up-to-date.
        </p>
      </Section>
    </Page>
  );
};

export default Privacy;
