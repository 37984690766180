import React from 'react';
import Helmet from 'react-helmet';
import Page from 'components/Page';
import Section from 'components/Page';
import { SectionHeading } from 'components/Elements';
import s from './style.module.css';

const CommunityGuidelines = () => {
  return (
    <Page>
      <Helmet title="Community Guidelines" />
      <Section className={s.root}>
        <SectionHeading centered>Community Guidelines</SectionHeading>
        <p>
          The MyOnlineSchool Community is a meeting-point for members to ask questions and exchange
          advice and tips with each other. Here, you’ll find many features such as Question and
          Answer communities for specific niche topics, and even our virtual cafes where you can
          simply chat and get to know each other. All of our communities are built with you in mind
          so you can learn from your fellow members who share your interests and passions. Be aware
          that this is a peer-to-peer support Community; MyOnlineSchool does not provide direct
          customer support to you via these boards. If you want to learn more on a subject, we
          advise that you take a MyOnlineSchool course, where you get personal feedback in our
          classrooms from real experts.
        </p>
        <p>
          If you are new to the MyOnlineSchool Community, taking a look at our community guidelines,
          such as the Discussion Boards Usage Policy and the Community Content Policy, is a great
          way to start. Knowing what is and is not allowed will be very helpful as you connect with
          other MyOnlineSchool Community members.
        </p>
        <p>
          Most importantly though - enjoy taking part in your Community and we're glad you're here!
        </p>
        <p>
          Thank you,
          <br />
          Amy
          <br />
          MyOnlineSchool Community Manager
        </p>
        <hr />
        <h3>Discussion Board Usage Policies</h3>
        <h4>Allowed</h4>
        <ul>
          <li>
            Threads and posts asking questions or answering questions to the best of your ability
            about niche topics
          </li>
          <li>
            Posts that respect the privacy of all MyOnlineSchool members and are courteous toward
            members, projects, and initiatives
          </li>
          <li>Posts that are courteous and treat others with respect</li>
        </ul>
        <h4>Restricted</h4>
        <ul>
          <li>
            You can generally advertise on here if your link is directly relates to a subject being
            discussed, but it must be deemed relevant by our moderators
          </li>
          <li>It can only be mentioned once per thread, per category</li>
        </ul>

        <h4>Not Allowed</h4>
        <ul>
          <li>
            Advertising items, listing, services, or commercial websites, including offers to trade,
            "wanted" posts, or charitable solicitations
          </li>
          <li>Attempting to impersonate other MyOnlineSchool members</li>
          <li>Creating additional user IDs to avoid posting limits</li>
          <li>Discussing or reposting deleted posts or warning letters</li>
          <li>
            Discussing restricted or suspended members, or members who are no longer registered
          </li>
          <li>
            Enabling, encouraging, or instructing others to leave MyOnlineSchool so that they can
            learn or participate on sites other than MyOnlineSchool that includes the sale of online
            courses
          </li>
          <li>Hostile comments and interpersonal disputes</li>
          <li>Posting an individual's contact information or email address</li>
          <li>JavaScript or active code</li>
          <li>Misusing the Report link to maliciously or frivolously report posts</li>
          <li>Posting email content from other individuals</li>
          <li>
            Posting information about member or listing violations (Instead, please review our
            policies and report the violation by contacting us
          </li>
          <li>
            Posting web addresses (URLs), websites, in the title of a post or threaded discussion
          </li>
          <li>Refusing to follow MyOnlineSchool staff instructions</li>
          <li>Repetitive, disruptive, or inappropriate threads</li>
          <li>Unacceptable images or text</li>
          <li>Other actions that interfere with site operations </li>
        </ul>
        <hr />
        <h3>Community Content Policy</h3>
        <p>
          Community content published or edited by our members is solely the opinion and
          responsibility of the persons posting the content.
        </p>
        <p>
          It's important that your content follows these guidelines. If it doesn't, it may be
          removed or hidden, and you may be subject to a range of other actions, including
          restrictions of your buying and selling privileges and suspension of your account. To
          report violations of this policy, click the Report link in the content in question (such
          as a review, member-created product description, or comment) or, for a collection, send an
          email.
        </p>
        <h4>Restricted</h4>
        <ul>
          <li>Using profanity or vulgar language in a public area of the site is not allowed</li>
        </ul>
        <h4>Not allowed</h4>
        <ul>
          <li>
            Here are some examples of content or actions that aren't permitted in the community
            content areas (Reviews, Guides, member-created product descriptions, and comments).
            These examples provide guidance for self-publishing content on our site but shouldn't be
            viewed as a complete list
          </li>
          <li>
            Promoting or advertising to buy, sell, or trade any product or service listed or located
            outside of MyOnlineSchool
          </li>
          <li>Placing published content in the wrong category (miscategorization)</li>
          <li>Including feedback or comments about other users</li>
          <li>Including unauthorized trademarked or copyrighted content</li>
          <li>
            Using another MyOnlineSchool member's pictures or text without the other member's
            express permission
          </li>
          <li>
            Using intellectual property without the property owner's express permission, when
            required. This includes any advertising credit, including links, logos, or company names
            within published content
          </li>
          <li>
            Publishing more than one identical piece of content at the same time and in the same
            context
          </li>
          <li>Publishing content in the incorrect forum</li>
          <li>Publishing reviews that aren't specific to the product</li>
          <li>
            Enabling, encouraging, or instructing others to infringe copyrights, trademarks, or
            other intellectual property rights
          </li>
          <li>Promoting giveaways, lotteries, random drawings, raffles, or prizes of any kind</li>
          <li>
            Misrepresenting yourself as an MyOnlineSchool employee or as another MyOnlinSchool
            member
          </li>
          <li>
            Publishing content that promotes or glorifies hatred, violence, racial, or religious
            intolerance, or content that promotes organizations with such views
          </li>
          <li>Using profanity or patently vulgar language in a public area of the site</li>
          <li>
            Publishing personal contact information of any person in any public area of
            MyOnlineSchool including emails, phone numbers, name, street address, etc
          </li>
          <li>
            Artificially increasing or decreasing any community content, including ratings and
            reviews
          </li>
        </ul>
        <h4>Other rules</h4>
        <ul>
          <li>You must be a registered and active member of MyOnlineSchoolto post a comment</li>
          <li>
            We may promote, demote or remove any of your content in community content areas in our
            sole discretion.
          </li>
          <li>We may limit the amount of community content you post</li>
          <li>
            When a user refers in community content to an individual, or to a third party's product,
            service, or trademark, that does not mean MyOnlineSchool endorses or claims any
            endorsement by the individual or third party, nor does it mean we are affiliated.{' '}
          </li>
          <li>You acknowledge that all posts you make are public</li>
          <li>
            While we may moderate or monitor any comment, we take no responsibility and assume no
            liability for any comment posted by you or a third party
          </li>
        </ul>
      </Section>
    </Page>
  );
};

export default CommunityGuidelines;
