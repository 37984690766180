import React from 'react';
import { Section } from 'components/Layout';
import styled from 'styled-components';
import { font, Title as BaseTitle, Body as BaseBody } from 'components/Typography';
import Showreel from 'components/Showreel';

const StyledSection = styled(({ className, ...props }) => (
  <Section containerClassName={className} {...props} />
))`
  background-color: ${({ theme }) => theme.schools.foodanddrink.primary};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0;
  background-color: ${({ theme }) => theme.schools.foodanddrink.primary};
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
    align-items: center;
    padding: 50px 20px 50px 20px;
  }
`;

const Description = styled.div`
  position: relative;
  margin: 15px 0;
  padding: 0 15px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 20px 0;
    padding: 0 45px;
    flex: 0 1 auto;
    align-self: center;
  }
`;

const Title = styled(BaseTitle).attrs({
  mobile: font.medium.group(8),
  desktop: font.medium.group(9),
  hd: font.medium.group(10),
  white: true,
  as: 'h1',
})``;

const Body = styled(BaseBody).attrs({
  mobile: font.medium.group(4),
  desktop: font.book.group(2),
  hd: font.book.group(5),
  white: true,
  as: 'p',
})`
  margin: 20px 0 28px 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 16px 0 24px 0;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    margin: 30px 0 26px 0;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.global.black};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 65%;
    position: relative;
    flex: 1 0 65%;
  }
`;

const OurMission = () => {
  return (
    <StyledSection wide>
      <Container>
        <VideoContainer>
          <Showreel />
        </VideoContainer>
        <Description>
          <Title>About Learning with Experts</Title>
          <Body>
            Learning with Experts is a global classroom community that brings people together to
            learn from expert tutors and each other.
          </Body>
        </Description>
      </Container>
    </StyledSection>
  );
};

export default OurMission;
